import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

import { ThemeProvider } from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import { theme } from '../../helpers/utils';
import GlobalStyles from '../../styles/globalStyles';

// Components
// import PageLoader from '../common/PageLoader/PageLoader';
const PageLoader = dynamic(() => import('../common/PageLoader/PageLoader', { ssr: false }));

const handleExitComplete = () => {
  setTimeout(() => {
    if (typeof window !== 'undefined') {
      const hashId = window.location.hash;

      if (hashId) {
        const element = document.querySelector(hashId);

        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }
      }
    }
  }, 2000);
};

function Theme({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <PageLoader />
      <AnimatePresence exitBeforeEnter onExitComplete={handleExitComplete}>
        {children}
      </AnimatePresence>
    </ThemeProvider>
  );
}

Theme.propTypes = { children: PropTypes.node };

export default Theme;
