import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import '../styles/globals.css';
import '../styles/main.scss';

// Components
import Providers from '../components/Providers';

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  return (
    <Providers languageCode={pageProps.languageCode}>
      <Component {...pageProps} key={router.route} />
    </Providers>
  );
}

export default MyApp;

MyApp.getInitialProps = async ({ Component, ctx }) => {
  const ip = ctx?.req?.headers['x-forwarded-for'] || ctx.req?.connection?.remoteAddress;
  const userAgent = ctx?.req?.headers['user-agent'];

  const isMobile = Boolean(userAgent && userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i));
  const deviceType = isMobile ? 'mobile' : 'desktop';
  let deviceDetail;
  if (isMobile) {
    const isIphone = Boolean(userAgent && userAgent.match(/iPhone|iPad|iPod/i));
    const isAndroid = Boolean(userAgent && userAgent.match(/Android/i));
    deviceDetail = {
      isIphone,
      isAndroid,
      deviceType,
    };
  } else {
    deviceDetail = {
      isIphone: false,
      isAndroid: false,
      deviceType,
    };
  }

  let pageProps = {};

  // bring in the initial props from a rendered component if available
  if (Component?.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  return { pageProps: { ...pageProps, req: { ip, userAgent, deviceDetail } } };
};

MyApp.propTypes = {
  pageProps: PropTypes.object,
  Component: PropTypes.func,
};
