/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 * For more details on using MSAL.js with Azure AD B2C, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/working-with-b2c.md
 *
 * redirectUri: "https://scene-dev-customerfrontend.azurewebsites.net/",
 */
// for cypress test - modification for auth token and successful login

import { msalConfigMock } from '../cypress/support/authConfigMock';

function setupMsalConfig() {
  if (process.env.NODE_ENV === 'test' || (typeof window !== 'undefined' && window?.Cypress)) {
    return msalConfigMock;
  }

  return {
    auth: {
      clientId: `${process.env.NEXT_PUBLIC_CLIENT_ID}`,
      authority: `${process.env.NEXT_PUBLIC_AUTHORITY_URL}`,
      knownAuthorities: [`${process.env.NEXT_PUBLIC_KNOWN_AUTHORITY}`],
      redirectUri: `${process.env.NEXT_PUBLIC_REDIRECT_URI}`,
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false,
      secureCookies: false,
    },
  };
}

export const msalConfig = setupMsalConfig();

export const loginRequest = {
  scopes: [`${process.env.NEXT_PUBLIC_SCOPE_URL}/User.Read`],
  prompt: 'login',
};

export const b2cPolicies = {
  names: {
    passwordChange: 'b2c_1a_passwordchange',
    emailChange: 'b2c_1a_emailchange',
    phoneChange: 'b2c_1a_phonechange',
    forgotPassword: 'b2c_1a_passwordreset',
    signIn: 'b2c_1a_signin',
    authorityDomain: `${process.env.NEXT_PUBLIC_KNOWN_AUTHORITY}`,
  },
  authorities: {
    passwordChange: {
      authority: `${process.env.NEXT_PUBLIC_B2C_URL}/b2c_1a_passwordchange`,
    },
    emailChange: {
      authority: `${process.env.NEXT_PUBLIC_B2C_URL}/b2c_1a_emailchange`,
    },
    phoneChange: {
      authority: `${process.env.NEXT_PUBLIC_B2C_URL}/b2c_1a_phonechange`,
    },
    forgotPassword: {
      authority: `${process.env.NEXT_PUBLIC_B2C_URL}/b2c_1a_passwordreset`,
    },
    signIn: {
      authority: `${process.env.NEXT_PUBLIC_B2C_URL}/b2c_1a_signin`,
    },
    authorityDomain: `${process.env.NEXT_PUBLIC_KNOWN_AUTHORITY}`,
  },
};
